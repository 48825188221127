
<template>
  <div class="admin-couriers-listing">
    <title-bar addBtn exportBtn title="Couriers" :addUrl="getAddCourierURL()" />
    <search class="mb-4" @inputChange="inputChange" />
    <vue-good-table
      class="custom-table-style possible-vertical-align"
      mode="remote"
      :totalRows="pages"
      :isLoading.sync="isLoading"
      :columns="columns"
      :rows="couriers"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
        setCurrentPage: currentPage,
      }"
      @on-page-change="onPageChange"
    >
      <template slot="table-row" slot-scope="props">
        <span :class="props.column.field" v-if="props.column.field == 'status'">
          <table-bullet :value="props.row.onOff" />
        </span>

        <span
          :class="props.column.field"
          v-else-if="props.column.field == 'courier-name'"
        >
          <div class="name">
            <div v-if="props.row.fileUrl" class="courier-photo">
              <img
                :src="`${props.row.courier.fileUrl}`"
                :alt="`${props.row.givenName} ${props.row.familyName}`"
              />
            </div>
            <span>{{ props.row.givenName }}</span>
          </div>
        </span>

        <span
          class="d-flex align-items-center"
          :class="props.column.field"
          v-else-if="props.column.field == 'telephone'"
        >
          {{ props.formattedRow[props.column.field] }}
          <call-btn
            class="ml-2"
            v-if="props.formattedRow[props.column.field]"
            :number="props.formattedRow[props.column.field]"
          />
        </span>

        <span v-else-if="props.column.field == 'car'">
          <div class="dropdown-container">
            <div class="main-dropdown">
              <b-form-group>
                <v-select
                  v-model="props.row.car"
                  :options="carsOptions"
                  class="custom-select"
                  :clearable="true"
                  :searchable="false"
                  @input="updateCourier(props.row)"
                >
                </v-select>
              </b-form-group>
            </div>
          </div>
        </span>

        <span v-else-if="props.column.field == 'enabled'">
          <b-badge :variant="props.row.enabled ? 'success' : 'danger'"
            >{{ props.row.enabled ? 'ENABLED' : 'DISABLED' }}
          </b-badge>
        </span>

        <span class="buttons" v-else-if="props.column.field == 'id'">
          <div class="just-buttons">
            <edit-btn
              :path="getEditCourierURL(props.formattedRow[props.column.field])"
            />
            <delete-btn @pressDelete="deleteCourier(props.row['@id'])" />
          </div>
        </span>

        <span :class="props.column.field" v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <template slot="pagination-bottom" slot-scope="props">
        <table-pagination
          v-model="currentPage"
          :total="pages"
          :per-page="perPage"
          :pageChanged="props.pageChanged"
          :perPageChanged="props.perPageChanged"
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import TitleBar from '@/components/TitleBar.vue'
import TablePagination from '@/components/Table/TablePagination.vue'
import TableBullet from '@/components/Table/TableBullet.vue'
import Search from '@/components/Inputs/Search.vue'
import CallBtn from '@/components/Buttons/CallBtn.vue'
import EditBtn from '@/components/Buttons/EditBtn.vue'
import DeleteBtn from '@/components/Buttons/DeleteBtn.vue'
import Toast from '@/components/Toast.vue'

export default {
  components: {
    TitleBar,
    Search,
    VueGoodTable,
    TablePagination,
    TableBullet,
    CallBtn,
    EditBtn,
    DeleteBtn,
  },
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      search: '',
      isLoading: false,
      couriers: null,
      cars: null,
      carsOptions: [],
      perPage: 15,
      currentPage: 1,
      pages: 0,
      lookUp: [],
      columns: [
        {
          field: 'status',
          width: '50px',
          sortable: false,
        },
        {
          field: 'courier-name',
          sortable: false,
        },
        {
          field: 'telephone',
          sortable: false,
        },
        {
          field: 'car',
          sortable: false,
        },
        {
          field: 'enabled',
          sortable: false,
        },
        {
          field: 'id',
          sortable: false,
          width: '100%',
        },
      ],
    }
  },
  created() {
    this.$Cars.getCollection({}, 'car_list').then(response => {
      this.cars = response.data['hydra:member']
      this.cars.forEach(car => {
        this.carsOptions.push({
          label: car.name,
          id: car['@id'],
          numberId: car.id,
        })
      })
      this.loadCouriers()

    })
  },
  methods: {
    loadCouriers() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        role: ['ROLE_COURIER'],
        'order[enabled]': 'desc'
      }
      if (this.search && this.search.length) {
        params.search = this.search
      }
      this.$Users.getCollection({ params }, 'courier_list').then(response => {
        this.pages = response.data['hydra:totalItems']
        this.couriers = response.data['hydra:member']
        this.couriers.forEach(courier => {
          const hasCar = this.cars.find(car => {
            if (car.courier && car.courier.id === courier.id) {
              return true
            }
            return false
          })
          if (hasCar) {
            courier.car = { label: hasCar.name, id: hasCar['@id'] }
            this.lookUp[courier['@id']] = courier.car.id
          } else {
            courier.car = 'Choose'
          }
        })
      })
    },
    deleteCourier(url) {
      this.$Users
        .deleteResourceByUrl({ url })
        .then(response => {
          if (response.status === 204) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              'Courier deleted',
              '',
              'success',
            )
            this.loadCouriers()
          }
        })
        .catch(error => {
          if (error.response) {
            this.$emit('clearAction')
            this.$helper.showToast(
              this.$toast,
              Toast,
              'Failed to delete',
              '',
              'danger',
            )
          }
        })
    },
    /* eslint-disable no-restricted-syntax */
    /* eslint-disable guard-for-in */
    async updateCourier(courier) {
      const prevCarID = this.lookUp[courier['@id']]
      for (const property in this.lookUp) {
        if (courier.car && courier.car.id === this.lookUp[property]) {
          this.$helper.showToast(this.$toast, Toast, "Car is already assigned", '', 'danger')
          return
        }
      }
      try {
          if (courier.car) {
            if (prevCarID) {
              await this.$Cars.updateResourceByUrl({ url: prevCarID, body: { courier: null } })
              this.lookUp[courier['@id']] = null
            }
            const res = await this.$Cars.updateResourceByUrl({ url: courier.car.id, body: { courier: courier['@id'] } })
            if (res.status === 200) {
              this.lookUp[courier['@id']] = courier.car.id
              this.$helper.showToast(this.$toast, Toast, "Car's courier updated", '', 'success')
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (prevCarID) {
              const res = await this.$Cars.updateResourceByUrl({ url: prevCarID, body: { courier: null } })
              if (res.status === 200) {
                this.lookUp[courier['@id']] = courier.car.id
                this.$helper.showToast(this.$toast, Toast, "Car's courier updated", '', 'success')
              }
            }
          }
        } catch (error) {
          if (error.response) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              error.response.data['hydra:title'],
              error.response.data['hydra:description'],
              'danger',
            )
          }
        }
    },
    inputChange(value) {
      this.search = value
      this.loadCouriers()
    },
    onPageChange() {
      this.loadCouriers()
    },
    getAddCourierURL() {
      return `/add-courier`
    },
    getEditCourierURL(id) {
      return `/couriers/${id}/edit`
    },
  },
}
</script>
